import moment from 'moment';
import { getToken, getSignedInTime, setSignedInTime } from '../helpers/utility';

import { store } from './store';
import authActions from './auth/actions';

export default () => {
  return new Promise(() => {
    const token = getToken();
    if (token != null) {
      const signedInTime = getSignedInTime();
      if (!signedInTime) {
        setSignedInTime(moment().format());
      }

      let locationHash = {
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash
      };

      if (window.location.pathname !== '/') {
        //Don't update referer if root path
        store.dispatch(authActions.updateReferer(locationHash));
      }

      if (!store.getState().auth.hasCheckedCompanySetup) {
        store.dispatch(authActions.checkCompanySetup());

        if (!store.getState().auth.user) {
          store.dispatch(authActions.getCurrentUserProfile());
        }
      }
    }
  });
};
