import { store } from "../redux/store";
import appActions from "../redux/app/actions";

let currentRequestsCount = 0;
let started = false;

export default function appProgress(state) {
  if (state === "RequestLoad") {
    currentRequestsCount++;
  }
  if (state === "RequestEnd") {
    currentRequestsCount--;
  }
  if (currentRequestsCount === 1 && !started) {
    started = true;
    store.dispatch(appActions.startLoading());
  }
  if (currentRequestsCount === 0 && started) {
    started = false;
    store.dispatch(appActions.stopLoading());
  }

  if (state === "ComponentLoadStart") {
    store.dispatch(appActions.startComponentLoading());
  }
  if (state === "ComponentLoadEnd") {
    store.dispatch(appActions.stopComponentLoading());
  }
}
