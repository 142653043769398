import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from '../../helpers/AsyncFunc';
import { allRoutes } from '../../routes/routes';

const routesOption = [
  {
    path: '',
    key: 'baseAuth',
    component: asyncComponent(() => import('../Dashboard/Dashboard'))
  },
  {
    path: allRoutes.PROFILE_PATH,
    key: 'profile',
    component: asyncComponent(() => import('../Profile/Profile'))
  },
  {
    path: allRoutes.DASHBOARD_PATH,
    key: 'dashboard',
    component: asyncComponent(() => import('../Dashboard/Dashboard'))
  },
  {
    path: allRoutes.EDIT_PROFILE_PATH,
    key: 'editProfile',
    component: asyncComponent(() => import('../Profile/EditProfile'))
  },
  {
    path: allRoutes.COMPANY_PATH,
    key: 'company',
    component: asyncComponent(() => import('../Company/Company'))
  },
  {
    path: allRoutes.SETUP_COMPANY_PATH,
    key: 'setupCompany',
    component: asyncComponent(() => import('../Company/SetupCompany'))
  },
  {
    path: allRoutes.NEW_COMPANY_PATH,
    key: 'newCompany',
    component: asyncComponent(() => import('../Company/NewCompany'))
  },
  {
    path: allRoutes.EDIT_COMPANY_PATH,
    key: 'editCompany',
    component: asyncComponent(() => import('../Company/EditCompany'))
  },
  {
    path: allRoutes.REPORT_PATH,
    key: 'report',
    component: asyncComponent(() => import('../Report/Report'))
  },
  {
    path: allRoutes.LOCATIONS_PATH,
    key: 'locations',
    component: asyncComponent(() => import('../Locations/LocationList'))
  },
  {
    path: allRoutes.NEW_LOCATION_PATH,
    key: 'newLocation',
    component: asyncComponent(() => import('../Locations/NewLocation'))
  },
  {
    path: allRoutes.LOCATION_PATH,
    key: 'location',
    component: asyncComponent(() => import('../Locations/LocationDetail'))
  },
  {
    path: allRoutes.EDIT_LOCATION_PATH,
    key: 'editLocation',
    component: asyncComponent(() => import('../Locations/EditLocation'))
  },
  {
    path: allRoutes.USERS_PATH,
    key: 'users',
    component: asyncComponent(() => import('../Users/UserList'))
  },
  {
    path: allRoutes.IMPORT_USERS_PATH,
    key: 'importUsers',
    component: asyncComponent(() => import('../Users/ImportUsers'))
  },
  {
    path: allRoutes.NEW_USER_PATH,
    key: 'newUser',
    component: asyncComponent(() => import('../Users/NewUser'))
  },
  {
    path: allRoutes.USER_PATH,
    key: 'user',
    component: asyncComponent(() => import('../Users/UserDetail'))
  },
  {
    path: allRoutes.EDIT_USER_PATH,
    key: 'editUser',
    component: asyncComponent(() => import('../Users/EditUser'))
  }
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routesOptions.map((singleRoute) => {
            const {
              path,
              exact,
              key,
              allowedRoles,
              ...otherProps
            } = singleRoute;
            return (
              <Route
                key={key}
                exact={true}
                path={`${url}/${path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
export const routesOptions = routesOption;
