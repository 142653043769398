import axios from 'axios';
import _ from 'lodash';

import { ErrorCodeMessages } from '../languages/errors';
import { store } from '../redux/store';
import appActions from '../redux/app/actions';

import { getToken } from './utility';
import { environments } from './environments';
import appProgress from './appProgress';

let requestCount = 0;

const fetch = (options) => {
  let { method = 'get', data, url, isRequireAuth = true } = options;

  const reqUrl = environments[process.env.REACT_APP_STAGE]['API_URL'] + url;

  const requestParams = {
    url: reqUrl,
    method: method.toLowerCase(),
    headers: { 'Content-Type': 'application/json' }
  };
  const cloneData = _.cloneDeep(data);

  switch (method.toLowerCase()) {
    case 'get':
      requestParams.params = cloneData;
      break;
    case 'delete':
    case 'post':
      requestParams.data = cloneData;
      break;
    case 'put':
      requestParams.data = cloneData;
      break;
    case 'patch':
      requestParams.data = cloneData;
      break;
    default:
      return axios(options);
  }

  if (isRequireAuth) {
    requestParams.headers = {
      ...requestParams.headers,
      Authorization: `Bearer ${getToken()}`
    };
  }

  return axios(requestParams);
};

export default function request(options) {
  let requestNumber = ++requestCount;
  const { isPolling, url } = options;
  if (!isPolling) appProgress('RequestLoad');
  if (!isPolling) console.log(`-> ${requestNumber} request: `, options);

  return fetch(options)
    .then((response) => {
      console.log(`-> ${requestNumber} response: `, url, response);

      let responseData = response.data;

      if (!isPolling)
        console.log(`-> ${requestNumber} response: `, url, responseData);

      if (responseData.status === 'success') {
        appProgress('RequestEnd');
        console.groupEnd();

        return Promise.resolve({
          success: true,
          data: responseData.data,
          current_page: responseData.current_page,
          per_page: responseData.per_page,
          last_page: responseData.last_page,
          total: responseData.total,
          licenses_expired_date: responseData.licenses_expired_date
        });
      } else {
        const locale = store.getState().app.locale;

        store.dispatch({
          type: appActions.SHOW_ALERT,
          alertType: 'error',
          title: locale === 'en' ? `Error` : 'เกิดข้อผิดพลาด',
          message: `${
            responseData.status_code
              ? ErrorCodeMessages[`code_${responseData.status_code}`][locale]
              : ErrorCodeMessages.code_000000[locale]
          }`
        });

        return Promise.resolve({
          success: false
        });
      }
    })
    .catch((error) => {
      const { response } = error;
      const { data, status } = response;
      const { status_code } = data;
      console.error(`-> ${requestNumber} error: `, response);

      const locale = store.getState().app.locale;

      store.dispatch({
        type: appActions.SHOW_ALERT,
        alertType: 'error',
        title: locale === 'en' ? `Error` : `เกิดข้อผิดพลาด`,
        message: `${
          status_code
            ? ErrorCodeMessages[`code_${status_code}`][locale]
            : ErrorCodeMessages.code_000000[locale]
        }`
      });
      appProgress('RequestEnd');
      console.groupEnd();

      return Promise.resolve({
        success: false,
        status: status,
        status_code: status_code
      });
    });
}
