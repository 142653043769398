import React from 'react';
import styled from 'styled-components';

import { Box } from '@material-ui/core';

const FooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
`;

export default function Footer(props) {
  return (
    <FooterWrapper>
      <Box p={1} fontSize={12} color="text.primary">
        Copyright © 2020
      </Box>
    </FooterWrapper>
  );
}
