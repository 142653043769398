import actions from './actions';

const initState = {
  isLoading: false,
  isComponentLoading: false,

  breadcrumbs: [],
  pageTitle: null,
  pageActions: [],
  pathKey: null,
  allowMenuList: [],

  shouldScrollTop: false,

  shouldAlertShow: false,
  alertMessage: null,
  alertTitle: null,
  alertType: null,

  locale: 'en'
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case actions.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };

    case actions.START_COMPONENT_LOADING:
      return {
        ...state,
        isComponentLoading: true
      };
    case actions.STOP_COMPONENT_LOADING:
      return {
        ...state,
        isComponentLoading: false
      };

    case actions.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs
      };
    case actions.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.title
      };
    case actions.SET_PAGE_ACTIONS:
      return {
        ...state,
        pageActions: action.pageActions
      };
    case actions.SET_PATH_KEY:
      return {
        ...state,
        pathKey: action.key
      };
    case actions.SET_ALLOW_MENU_LIST:
      return {
        ...state,
        allowMenuList: action.menuList
      };

    case actions.SHOW_ALERT:
      return {
        ...state,
        shouldAlertShow: true,
        shouldScrollTop: true,
        alertMessage: action.message,
        alertTitle: action.title,
        alertType: action.alertType
      };
    case actions.HIDE_ALERT:
      return {
        ...state,
        shouldAlertShow: false,
        shouldScrollTop: false,
        alertMessage: null,
        alertTitle: null,
        alertType: null
      };
    case actions.UPDATE_LOCALE:
      return {
        ...state,
        locale: action.locale
      };
    default:
      return state;
  }
}
