import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

const MenuButtonWrapper = styled.div`
  .menu-button.MuiButton-root {
    font-weight: 400;
    color: rgb(255, 255, 255, 0.54);
  }
  .menu-button.active.MuiButton-root {
    font-weight: 500;
    color: #ffffff;
  }
  .menu-button.MuiButton-root:hover {
    background: none;
    color: #ffffff;
  }
`;

function MenuButton(props) {
  const { currentPathKey, pathKey, to, children } = props;
  return (
    <MenuButtonWrapper>
      <Button
        fullWidth
        component={RouterLink}
        to={to}
        className={
          currentPathKey === pathKey ? 'menu-button active' : 'menu-button'
        }
      >
        {children}
      </Button>
    </MenuButtonWrapper>
  );
}

export default connect(
  state => ({
    currentPathKey: state.app.pathKey
  }),
  {}
)(MenuButton);
