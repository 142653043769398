import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from './app/reducer';
import auth from './auth/reducer';

const createRootReducer = history =>
  combineReducers({
    app,
    auth,
    router: connectRouter(history)
  });

export default createRootReducer;
