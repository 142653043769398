import _ from 'lodash';

export const defaultHeaderList = [
  'employee_code',
  'first_name',
  'first_name_en',
  'last_name',
  'last_name_en',
  'designation_name',
  'designation_name_en',
  'department_name',
  'department_name_en',
  'working_start_date',
  'provident_fund_employee',
  'provident_fund_company',
  'mobile_no',
  'email',
  'bank_account_no',
  'payment_type',
  'status'
];

export const defaultHeaderSetting = {
  employee_code: {
    id: 'employee_code',
    key: 'employee_code',
    label: 'employee_code',
    hasSet: false,
    isRequired: true,
    newKey: 'new_employee_code',
    newLabel: ''
  },
  first_name: {
    id: 'first_name',
    key: 'first_name',
    label: 'first_name',
    hasSet: false,
    isRequired: true,
    newKey: 'new_first_name',
    newLabel: ''
  },
  first_name_en: {
    id: 'first_name_en',
    key: 'first_name_en',
    label: 'first_name_en',
    hasSet: false,
    isRequired: false,
    newKey: 'new_first_name_en',
    newLabel: ''
  },
  last_name: {
    id: 'last_name',
    key: 'last_name',
    label: 'last_name',
    hasSet: false,
    isRequired: true,
    newKey: 'new_last_name',
    newLabel: ''
  },
  last_name_en: {
    id: 'last_name_en',
    key: 'last_name_en',
    label: 'last_name_en',
    hasSet: false,
    isRequired: false,
    newKey: 'new_last_name_en',
    newLabel: ''
  },
  email: {
    id: 'email',
    key: 'email',
    label: 'email',
    hasSet: false,
    isRequired: true,
    newKey: 'new_email',
    newLabel: ''
  },
  mobile_no: {
    id: 'mobile_no',
    key: 'mobile_no',
    label: 'mobile_no',
    hasSet: false,
    isRequired: true,
    newKey: 'new_mobile_no',
    newLabel: ''
  },
  designation_name: {
    id: 'designation_name',
    key: 'designation_name',
    label: 'designation_name',
    hasSet: false,
    isRequired: false,
    newKey: 'new_designation_name',
    newLabel: ''
  },
  designation_name_en: {
    id: 'designation_name_en',
    key: 'designation_name_en',
    label: 'designation_name_en',
    hasSet: false,
    isRequired: false,
    newKey: 'new_designation_name_en',
    newLabel: ''
  },
  department_name: {
    id: 'department_name',
    key: 'department_name',
    label: 'department_name',
    hasSet: false,
    isRequired: false,
    newKey: 'new_department_name',
    newLabel: ''
  },
  department_name_en: {
    id: 'department_name_en',
    key: 'department_name_en',
    label: 'department_name_en',
    hasSet: false,
    isRequired: false,
    newKey: 'new_department_name_en',
    newLabel: ''
  },
  working_start_date: {
    id: 'working_start_date',
    key: 'working_start_date',
    label: 'working_start_date',
    hasSet: false,
    isRequired: true,
    newKey: 'new_working_start_date',
    newLabel: ''
  },
  payment_type: {
    id: 'payment_type',
    key: 'payment_type',
    label: 'payment_type',
    hasSet: false,
    isRequired: false,
    newKey: 'new_payment_type',
    newLabel: ''
  },
  bank_account_no: {
    id: 'bank_account_no',
    key: 'bank_account_no',
    label: 'bank_account_no',
    hasSet: false,
    isRequired: false,
    newKey: 'new_bank_account_no',
    newLabel: ''
  },
  provident_fund_employee: {
    id: 'provident_fund_employee',
    key: 'provident_fund_employee',
    label: 'provident_fund_employee',
    hasSet: false,
    isRequired: false,
    newKey: 'new_provident_fund_employee',
    newLabel: ''
  },
  provident_fund_company: {
    id: 'provident_fund_company',
    key: 'provident_fund_company',
    label: 'provident_fund_company',
    hasSet: false,
    isRequired: false,
    newKey: 'new_provident_fund_company',
    newLabel: ''
  },
  status: {
    id: 'status',
    key: 'status',
    label: 'status',
    hasSet: false,
    isRequired: false,
    newKey: 'new_status',
    newLabel: ''
  }
};

export function tryCatchValue(tryMe) {
  try {
    return tryMe();
  } catch (e) {
    return undefined;
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatNumber(number) {
  if (_.isNaN(Number(number))) {
    return 'N/A';
  } else {
    return Number(number).toLocaleString('th-TH');
  }
}

export function formatPrice(number) {
  if (_.isNaN(Number(number))) {
    return 'N/A';
  } else {
    return Number(number).toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}

export function formatMobile(mobile) {
  mobile = mobile.toString();

  if (mobile.length < 10) {
    mobile = _.padStart(mobile, 10, '0');
  }

  return mobile
    .split(' ')
    .join('')
    .split('-')
    .join('')
    .replace(/^(\d{2})(\d{4})(\d{4})$/g, '$1 $2 $3');
}

export function formatBankAccount(bankAccountNo) {
  bankAccountNo = bankAccountNo.toString();

  if (bankAccountNo.length < 10) {
    bankAccountNo = _.padStart(bankAccountNo, 10, '0');
  }

  return bankAccountNo
    .split('-')
    .join('')
    .split(' ')
    .join('')
    .replace(/^(\d{3})(\d{1})(\d{5})(\d{1})$/g, '$1-$2-$3-$4');
}

export function setToken(token) {
  localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function clearToken() {
  localStorage.removeItem('token');
}

export function setSignedInTime(signedInTime) {
  localStorage.setItem('signedInTime', signedInTime);
}

export function getSignedInTime() {
  return localStorage.getItem('signedInTime');
}

export function clearSignedInTime() {
  localStorage.removeItem('signedInTime');
}

export function setLicense(license) {
  localStorage.setItem('license', license);
}

export function getLicense() {
  let license = localStorage.getItem('license');
  if (!license) {
    license = '';
  }

  return license;
}

export function clearLicense() {
  localStorage.removeItem('license');
}
