import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import createRootReducer from './reducers';
import rootSaga from './sagas';

import { pathFor } from '../routes/routes';
import { tryCatchValue } from '../helpers/utility';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

global.pathFor = pathFor;
global.tryCatchValue = tryCatchValue;

export { store, history };
