import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Breadcrumbs, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiHomeCircleOutline } from '@mdi/js';
const LinkRouter = props => <Link {...props} component={RouterLink} />;
const BreadcrumWrapper = styled.div`
  nav {
    line-height: 1;
  }
  .MuiBreadcrumbs-ol {
    justify-content: flex-start;
  }
  .MuiBreadcrumbs-separator {
    color: #4e4e4e;
  }
  .MuiBreadcrumbs-li .MuiLink-underlineHover:hover {
    text-decoration: none;
  }
`;

function CustomBreadcrumbs(props) {
  const { breadcrumbs } = props;

  return (
    <BreadcrumWrapper>
      {breadcrumbs && (
        <Breadcrumbs aria-label="breadcrumb" separator="●">
          <LinkRouter to={global.pathFor('USERS_PATH')} key="home">
            <Box lineHeight={0.5}>
              <Icon path={mdiHomeCircleOutline} size={1} color="#4a4a4a" />
            </Box>
          </LinkRouter>

          {breadcrumbs.map(breadcrumb => {
            return breadcrumb.disabled ? (
              <Box color="primary.main" fontSize={14} key={breadcrumb.key}>
                {breadcrumb.title}
              </Box>
            ) : (
              <LinkRouter to={breadcrumb.path} key={breadcrumb.key}>
                <Box color="text.primary" fontSize={14}>
                  {breadcrumb.title}
                </Box>
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      )}
    </BreadcrumWrapper>
  );
}

export default connect(
  state => ({
    breadcrumbs: state.app.breadcrumbs
  }),
  {}
)(CustomBreadcrumbs);
