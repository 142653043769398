import React from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';

import { store, history } from './redux/store';
import Boot from './redux/boot';
import PublicRoutes from './routes/router';
import theme from './theme';
import './styles/main.css';

const DashApp = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PublicRoutes history={history} />
    </Provider>
  </ThemeProvider>
);

Boot()
  .then(() => DashApp())
  .catch((error) => console.error(error));

export default DashApp;
