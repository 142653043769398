import React from 'react';
import styled from 'styled-components';

const PageLayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  background-color: #f5f5f5;
`;

export default (props) => (
  <PageLayoutWrapper
    style={props.style}
    className={
      props.wrapperClassName != null ? `${props.wrapperClassName}` : ''
    }
    {...props}
  >
    {props.children}
  </PageLayoutWrapper>
);
