import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import asyncComponent from '../helpers/AsyncFunc';
import AppLocale from '../languages';
import App from '../views/App/App';

import { pathFor } from './routes';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: pathFor('SIGN_IN_PATH'),
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const AuthRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Redirect
          to={{
            pathname: pathFor('BASE_AUTH_PATH'),
            state: { from: props.location }
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const PublicRoutes = ({ history, isLoggedIn, locale }) => {
  const currentAppLocaleMessage = AppLocale[locale];

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={currentAppLocaleMessage}
    >
      <ConnectedRouter history={history}>
        <div>
          <AuthRoute
            exact
            path={'/'}
            component={asyncComponent(() =>
              import('../views/Authentication/SignIn/SignIn')
            )}
            isLoggedIn={isLoggedIn}
          />
          <AuthRoute
            exact
            path={pathFor('SIGN_IN_PATH')}
            component={asyncComponent(() =>
              import('../views/Authentication/SignIn/SignIn')
            )}
            isLoggedIn={isLoggedIn}
          />
          <AuthRoute
            exact
            path={pathFor('FORGOT_PASSWORD_PATH')}
            component={asyncComponent(() =>
              import('../views/Authentication/ForgotPassword/ForgotPassword')
            )}
            isLoggedIn={isLoggedIn}
          />
          <AuthRoute
            exact
            path={pathFor('RESET_PASSWORD_PATH')}
            component={asyncComponent(() =>
              import('../views/Authentication/ResetPassword/ResetPassword')
            )}
            isLoggedIn={isLoggedIn}
          />
          <RestrictedRoute
            path={pathFor('BASE_AUTH_PATH')}
            component={App}
            isLoggedIn={isLoggedIn}
          />
        </div>
      </ConnectedRouter>
    </IntlProvider>
  );
};

export default connect((state) => ({
  isLoggedIn: state.auth.token !== null,
  locale: state.app.locale
}))(PublicRoutes);
