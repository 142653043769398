import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, Paper } from '@material-ui/core';

import CustomBreadcrumbs from './CustomBreadcrumbs';

const PageHeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 64px;
  z-index: 2;
`;

function PageHeader(props) {
  const { pageTitle, pageActions } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageHeaderWrapper
      style={isSmallScreen ? { height: '120px' } : { height: '71px' }}
    >
      <Paper square={true} elevation={1} className="fill-height">
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className="fill-height"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box px={isSmallScreen ? 2 : 6}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    color="primary.main"
                    className="text-uppercase"
                    fontWeight="400"
                    fontSize={isSmallScreen ? 18 : 20}
                  >
                    {pageTitle}
                  </Box>
                  <CustomBreadcrumbs />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    pt={isSmallScreen ? 1 : 0}
                    textAlign={isSmallScreen ? 'center' : 'right'}
                  >
                    {pageActions}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </PageHeaderWrapper>
  );
}

export default connect(
  (state) => ({
    pageTitle: state.app.pageTitle,
    pageActions: state.app.pageActions
  }),
  {}
)(PageHeader);
