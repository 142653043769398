const actions = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',

  START_COMPONENT_LOADING: 'START_COMPONENT_LOADING',
  STOP_COMPONENT_LOADING: 'STOP_COMPONENT_LOADING',

  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  SET_PAGE_ACTIONS: 'SET_PAGE_ACTIONS',
  SET_PATH_KEY: 'SET_PATH_KEY',
  SET_ALLOW_MENU_LIST: 'SET_ALLOW_MENU_LIST',

  UPDATE_LOCALE: 'UPDATE_LOCALE',

  startLoading: () => ({
    type: actions.START_LOADING
  }),
  stopLoading: () => ({
    type: actions.STOP_LOADING
  }),

  startComponentLoading: () => ({
    type: actions.START_COMPONENT_LOADING
  }),
  stopComponentLoading: () => ({
    type: actions.STOP_COMPONENT_LOADING
  }),

  showAlert: (alertType, title, message) => ({
    type: actions.SHOW_ALERT,
    alertType,
    title,
    message
  }),
  hideAlert: () => ({
    type: actions.HIDE_ALERT
  }),

  setBreadcrumbs: (breadcrumbs) => ({
    type: actions.SET_BREADCRUMBS,
    breadcrumbs
  }),
  setPageTitle: (title) => ({
    type: actions.SET_PAGE_TITLE,
    title
  }),
  setPageActions: (pageActions) => ({
    type: actions.SET_PAGE_ACTIONS,
    pageActions
  }),
  setPathKey: (key) => ({
    type: actions.SET_PATH_KEY,
    key
  }),
  setAllowMenuList: (menuList) => ({
    type: actions.SET_ALLOW_MENU_LIST,
    menuList
  }),

  updateLocale: (locale) => ({
    type: actions.UPDATE_LOCALE,
    locale
  })
};

export default actions;
