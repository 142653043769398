const actions = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  CHECK_COMPANY_SETUP: 'CHECK_COMPANY_SETUP',
  TOGGLE_COMPANY_SETUP_CHECKING: 'TOGGLE_COMPANY_SETUP_CHECKING',
  LICENSE_INACTIVE: 'LICENSE_INACTIVE',

  GET_CURRENT_USER_PROFILE: 'GET_CURRENT_USER_PROFILE',
  UPDATE_CURRENT_USER_PROFILE: 'UPDATE_CURRENT_USER_PROFILE',
  UPDATE_REFERER: 'UPDATE_REFERER',
  UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',

  login: (payload, referer) => ({
    type: actions.LOGIN_REQUEST,
    data: payload,
    referer
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),

  checkCompanySetup: (fromSetup) => ({
    type: actions.CHECK_COMPANY_SETUP,
    fromSetup
  }),
  toggleCompanySetupChecking: (status) => ({
    type: actions.TOGGLE_COMPANY_SETUP_CHECKING,
    status
  }),

  getCurrentUserProfile: () => ({
    type: actions.GET_CURRENT_USER_PROFILE
  }),
  updateCurrentUserProfile: (data) => ({
    type: actions.UPDATE_CURRENT_USER_PROFILE,
    data
  }),
  updateReferer: (referer) => ({
    type: actions.UPDATE_REFERER,
    referer
  }),
  updateCurrentUser: (user) => ({
    type: actions.UPDATE_CURRENT_USER,
    user
  }),

  onTokenInvalid: () => ({
    type: actions.LOGIN_ERROR
  }),
  onLicenseInactive: () => ({
    type: actions.LICENSE_INACTIVE
  })
};
export default actions;
