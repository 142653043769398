import _ from 'lodash';
import queryString from 'query-string';
import { formatRoute } from 'react-router-named-routes';

const routes = {
  SIGN_IN_PATH: `sign_in`,
  FORGOT_PASSWORD_PATH: `forgot_password`,
  RESET_PASSWORD_PATH: `reset_password`,
  BASE_AUTH_PATH: `auth`
};

const authRoutes = {
  DASHBOARD_PATH: `dashboard`,
  PROFILE_PATH: `profile`,
  EDIT_PROFILE_PATH: `profile/edit`,
  COMPANY_PATH: `company`,
  SETUP_COMPANY_PATH: `setup`,
  REPORT_PATH: `report`,
  LOCATIONS_PATH: `locations`,
  NEW_LOCATION_PATH: `locations/new`,
  LOCATION_PATH: `locations/:location_id`,
  EDIT_LOCATION_PATH: `locations/:location_id/edit`,
  USERS_PATH: `employees`,
  IMPORT_USERS_PATH: `employees/import`,
  NEW_USER_PATH: `employees/new`,
  USER_PATH: `employees/:employee_id`,
  EDIT_USER_PATH: `employees/:employee_id/edit`
};

export const allRoutes = {
  ...routes,
  ...authRoutes
};

export function pathFor(pathOrPathName, params = {}, hash) {
  let isAuthPath = false;
  let path;
  if (Object.keys(authRoutes).includes(pathOrPathName)) {
    path = authRoutes[pathOrPathName];
    isAuthPath = true;
  } else if (Object.keys(routes).includes(pathOrPathName)) {
    path = routes[pathOrPathName];
  } else {
    path = pathOrPathName;
  }
  let cloneParams = _.cloneDeep(params);
  let formattedPath = formatRoute(`${path}`, params);
  //Check for keys that exist in path and remove from params and add the rest to query string)
  path
    .split('/')
    .filter((x) => x.includes(':'))
    .map((x) => x.slice(1))
    .forEach((key) => {
      delete cloneParams[key];
    });
  let query = queryString.stringify(cloneParams);
  if (isAuthPath) formattedPath = `${routes.BASE_AUTH_PATH}/${formattedPath}`;
  if (Object.keys(cloneParams).length > 0) return `/${formattedPath}?${query}`;
  if (hash) {
    return `/${formattedPath}#${hash}`;
  } else {
    return `/${formattedPath}`;
  }
}
