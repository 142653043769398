import request from '../../helpers/request';

const services = {
  login: (payload) => {
    return request({
      url: '/api/v1/admin/login',
      method: 'post',
      data: payload,
      isRequireAuth: false
    });
  },
  checkCompanySetup: () => {
    return request({
      url: `/api/v1/admin/dashboard/stats`,
      method: 'get'
    });
  },
  profile: () => {
    return request({
      url: '/api/v1/admin/profile',
      method: 'get'
    });
  },
  updateProfile: (payload) => {
    return request({
      url: '/api/v1/admin/profile',
      method: 'put',
      data: payload
    });
  },
  logout: () => {
    return request({
      url: '/api/v1/admin/logout',
      method: 'post'
    });
  }
};
export default services;
