import React, { useEffect, useState, createRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Fab, Grid } from '@material-ui/core';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

const ContentLayoutWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  padding-bottom: 56px;
}
`;

const ContentLayout = (props) => {
  const { shouldScrollTop } = props;
  const ref = createRef();

  const [showScroll, setShowScroll] = useState(false);

  const scrollTop = () => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };

  const checkScrollTop = () => {
    if (ref.current) {
      if (!showScroll && ref.current.scrollTop > 400) {
        setShowScroll(true);
      } else if (showScroll && ref.current.scrollTop <= 400) {
        setShowScroll(false);
      }
    }
  };

  useEffect(() => {
    ref.current.addEventListener('scroll', checkScrollTop);
  });

  useEffect(() => {
    if (shouldScrollTop && ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [ref, shouldScrollTop]);

  return (
    <ContentLayoutWrapper
      id="container-layout"
      ref={ref}
      style={props.style}
      className={
        props.wrapperClassName != null ? `${props.wrapperClassName}` : ''
      }
      {...props}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        className="page-container"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {props.children}
        </Grid>
      </Grid>

      <Fab
        color="primary"
        style={{
          position: 'absolute',
          bottom: '5%',
          right: '5%',
          opacity: showScroll ? '80%' : '0%',
          transition: 'opacity 500ms'
        }}
        onClick={scrollTop}
      >
        <UpIcon />
      </Fab>
    </ContentLayoutWrapper>
  );
};

export default connect(
  (state) => ({
    shouldScrollTop: state.app.shouldScrollTop
  }),
  {}
)(ContentLayout);
