import actions from './actions';
import { getToken, getSignedInTime } from '../../helpers/utility';

const initState = {
  user: null,
  company: null,
  token: getToken(),
  signedInTime: getSignedInTime(),
  referer: null,
  hasCheckedCompanySetup: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        signedInTime: action.timestamp
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        token: null,
        user: null
      };

    case actions.LOGOUT_SUCCESS:
      return {
        user: null,
        token: null,
        referer: null,
        signedInTime: null
      };

    case actions.TOGGLE_COMPANY_SETUP_CHECKING:
      return {
        ...state,
        hasCheckedCompanySetup: action.status
      };

    case actions.UPDATE_CURRENT_USER:
      return {
        ...state,
        user: action.user,
        company: action.company
      };
    case actions.UPDATE_REFERER:
      return {
        ...state,
        referer: action.referer
      };
    default:
      return state;
  }
}
