const environments = {
  dev: {
    // API_URL: 'https://dev-mas-api.sunthon.org'
    API_URL: 'https://dev.mashr.app'
  },
  production: {
    API_URL: 'https://service.mashr.app'
  }
};

export { environments };
