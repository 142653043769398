import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Mitr', 'sans-serif']
  },
  palette: {
    primary: {
      main: '#00ACEA',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#4e4e4e',
      contrastText: '#ffffff'
    },
    error: {
      main: '#d32f2f',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#ff9800',
      contrastText: '#ffffff'
    },
    success: {
      main: '#4caf50',
      contrastText: '#ffffff'
    },
    info: {
      main: '#115293',
      contrastText: '#ffffff'
    },
    background: {
      default: '#ffffff'
    },
    text: {
      primary: '#4e4e4e',
      secondary: '#ffffff'
    }
  }
});

export default theme;
