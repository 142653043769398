import React from 'react';
import { connect } from 'react-redux';

import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, Grid } from '@material-ui/core';

import appActions from '../../redux/app/actions';

const { hideAlert } = appActions;

function CustomAlert(props) {
  const {
    hideAlert,
    shouldAlertShow,
    alertType,
    alertTitle,
    alertMessage
  } = props;

  return shouldAlertShow ? (
    <Box pt={2} px={3}>
      <Grid container alignItems="center" justify="center" spacing={0}>
        <Grid item xs={12}>
          <Alert severity={alertType} onClose={hideAlert}>
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </Alert>
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

export default connect(
  state => ({
    shouldAlertShow: state.app.shouldAlertShow,
    alertMessage: state.app.alertMessage,
    alertTitle: state.app.alertTitle,
    alertType: state.app.alertType
  }),
  { hideAlert }
)(CustomAlert);
