import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: 'rgb(255, 255, 255, 0.6)',
    zIndex: 1200
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

export default function CustomProgress() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" size={60} className={classes.spinner} />
    </div>
  );
}
