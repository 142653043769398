import moment from 'moment';
import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import {
  setLicense,
  setSignedInTime,
  setToken,
  clearToken,
  clearSignedInTime
} from '../../helpers/utility';
import { pathFor } from '../../routes/routes';

import actions from './actions';
import appActions from '../app/actions';
import services from './services';

export const currentLocale = (state) => state.app.locale;

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ data, referer }) {
    const json = yield call(services.login, data);

    if (json.success) {
      const signedInAt = moment().format();
      setLicense(data.license);
      setSignedInTime(signedInAt);
      setToken(json.data.token);

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: json.data.token,
        timestamp: signedInAt,
        referer
      });
    } else {
      yield put({
        type: actions.LOGIN_ERROR
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* ({ token, referer }) {
    if (token) {
      yield put({
        type: actions.CHECK_COMPANY_SETUP,
        fromLoginSuccess: true,
        referer
      });
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {
    clearToken();
    clearSignedInTime();

    yield put(push(pathFor('SIGN_IN_PATH')));
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    const json = yield call(services.logout);
    if (json.success) {
      yield put({
        type: actions.LOGOUT_SUCCESS
      });

      clearToken();
      clearSignedInTime();

      yield put(push(pathFor('SIGN_IN_PATH')));
    }
  });
}

export function* checkCompanySetup() {
  yield takeEvery(actions.CHECK_COMPANY_SETUP, function* ({
    fromLoginSuccess,
    fromSetup,
    referer
  }) {
    const json = yield call(services.checkCompanySetup);
    if (json.success) {
      yield put({
        type: actions.TOGGLE_COMPANY_SETUP_CHECKING,
        status: true
      });

      if (json.data.license_status === 'active') {
        if (json.data.company_count === 0) {
          yield put(replace(pathFor('SETUP_COMPANY_PATH')));
        } else {
          if (fromLoginSuccess) {
            yield put({
              type: actions.GET_CURRENT_USER_PROFILE,
              fromLoginSuccess,
              referer
            });
          } else if (fromSetup) {
            yield put(replace(pathFor('BASE_AUTH_PATH')));
          }
        }
      } else {
        yield put({ type: actions.LICENSE_INACTIVE });
      }
    } else {
      if (json.status === 401) {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}
export function* onLicenseInactive() {
  yield takeEvery(actions.LICENSE_INACTIVE, function* () {
    const locale = yield select(currentLocale);

    yield put({
      type: appActions.SHOW_ALERT,
      alertType: 'error',
      title: locale === 'en' ? `Error` : 'เกิดข้อผิดพลาด',
      message:
        locale === 'en' ? 'License is inactivated.' : 'ไลเซนถูกปิดการใช้งาน'
    });
    yield put({ type: actions.LOGIN_ERROR });

    clearToken();
    clearSignedInTime();
  });
}

export function* getCurrentUserProfileRequest() {
  yield takeEvery(actions.GET_CURRENT_USER_PROFILE, function* ({
    fromLoginSuccess,
    referer
  }) {
    const json = yield call(services.profile);
    if (json.success) {
      const user = {
        ...json.data,
        full_name_th: `${json.data.first_name || ''} ${
          json.data.last_name || ''
        }`,
        full_name_en: `${json.data.first_name_en || ''} ${
          json.data.last_name_en || ''
        }`
      };

      const company = {
        company_code: json.data.company_code,
        company_id: json.data.company_id,
        company_name: json.data.company_name,
        company_name_en: json.data.company_name_en,
        fax_no: json.data.fax_no,
        tax_id: json.data.tax_id,
        address_detail: json.data.address_detail,
        address_detail_en: json.data.address_detail_en
      };

      yield put({
        type: actions.UPDATE_CURRENT_USER,
        user: user,
        company: company
      });

      if (fromLoginSuccess) {
        if (referer != null) {
          yield put(push(referer));
        } else {
          yield put(replace(pathFor('BASE_AUTH_PATH')));
        }
      }
    } else {
      if (json.status === 401) {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}
export function* updateCurrentUserProfileRequest() {
  yield takeEvery(actions.UPDATE_CURRENT_USER_PROFILE, function* ({ data }) {
    const json = yield call(services.updateProfile, data);
    if (json.success) {
      const user = {
        ...json.data,
        full_name_th: `${json.data.first_name || ''} ${
          json.data.last_name || ''
        }`,
        full_name_en: `${json.data.first_name_en || ''} ${
          json.data.last_name_en || ''
        }`
      };
      const company = {
        company_code: json.data.company_code,
        company_id: json.data.company_id,
        company_name: json.data.company_name,
        company_name_en: json.data.company_name_en,
        fax_no: json.data.fax_no,
        tax_id: json.data.tax_id,
        address_detail: json.data.address_detail,
        address_detail_en: json.data.address_detail_en
      };

      yield put({
        type: actions.UPDATE_CURRENT_USER,
        user: user,
        company: company
      });

      yield put(replace(pathFor('PROFILE_PATH')));
    } else {
      if (json.status === 401) {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(checkCompanySetup),
    fork(onLicenseInactive),
    fork(getCurrentUserProfileRequest),
    fork(updateCurrentUserProfileRequest)
  ]);
}
