import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import appActions from '../../redux/app/actions';

import PageLayout from '../../components/PageLayout/PageLayout';
import ContentLayout from '../../components/PageLayout/ContentLayout';
import Topbar from '../../components/Global/Topbar';
import PageHeader from '../../components/Global/PageHeader';
import CustomAlert from '../../components/Global/CustomAlert';
import CustomProgress from '../../components/Global/CustomProgress';
import Footer from '../../components/Global/Footer';

import AppRouter from './AppRouter';

const { hideAlert } = appActions;

function App(props) {
  const { url } = props.match;
  const {
    isLoading,
    isComponentLoading,
    shouldAlertShow,
    hideAlert,
    isAppReady
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (shouldAlertShow) {
      setTimeout(() => {
        hideAlert();
      }, 3000);
    }
  }, [shouldAlertShow, hideAlert]);

  return (
    <PageLayout>
      <Topbar />
      <PageHeader />

      <ContentLayout
        className="content-layout"
        style={
          isSmallScreen ? { paddingTop: '184px' } : { paddingTop: '128px' }
        }
      >
        <Box px={2}>
          <Box pt={isSmallScreen ? 2 : 3} className="page-container">
            <CustomAlert />
            {isAppReady && <AppRouter url={url} />}
          </Box>
        </Box>
      </ContentLayout>

      <Footer />

      {(isComponentLoading || isLoading || !isAppReady) && <CustomProgress />}
    </PageLayout>
  );
}

export default connect(
  (state) => ({
    isAppReady: state.auth.hasCheckedCompanySetup,
    shouldAlertShow: state.app.shouldAlertShow,
    isLoading: state.app.isLoading,
    isComponentLoading: state.app.isComponentLoading
  }),
  { hideAlert }
)(App);
