import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import appProgress from './appProgress';

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;

      const { default: Component } = await importComponent();

      if (this.mounted) {
        this.setState({
          component: (
            <Component
              {...this.props}
              notifyComponentLoadFinish={this.notifyComponentLoadFinish}
              notifyComponentLoadStart={this.notifyComponentLoadStart}
            />
          )
        });
      }
    }

    notifyComponentLoadStart() {
      appProgress('ComponentLoadStart');
    }

    notifyComponentLoadFinish() {
      appProgress('ComponentLoadEnd');
    }

    render() {
      const Component = this.state.component || <div />;
      return Component;
    }
  }

  return injectIntl(AsyncFunc);
}
