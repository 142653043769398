import React, { useEffect, useState, useMemo, forwardRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Icon from '@mdi/react';
import {
  mdiAccountCircle,
  mdiClose,
  mdiDotsVertical,
  mdiCheckboxBlankCircle
} from '@mdi/js';

import appActions from '../../redux/app/actions';
import authAction from '../../redux/auth/actions';

import MasLogoImage from '../../assets/logo/mas_logo_no_bg.svg';

import MenuButton from '../MenuButton/MenuButton';

const { updateLocale } = appActions;
const { logout } = authAction;

const useStyles = makeStyles((theme) => ({
  logoImage: {
    width: '100%',
    maxHeight: '25px'
  },
  logoImageSmall: {
    height: '25px'
  }
}));

const SmallScreenTopbarWrapper = styled.div`
  background-color: #00acea !important;

  .MuiListItemText-primary {
    font-weight: 300 !important;
    color: rgba(255, 255, 255, 0.8);
  }
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  .MuiListItem-root.Mui-selected .MuiListItemText-primary {
    font-weight: 400 !important;
    color: #ffffff;
  }
  .MuiListItem-root {
    padding-left: 36px;
  }
  .MuiListItemIcon-root {
    min-width: 24px;
  }
  .MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

function ListItemLink(props) {
  const { icon, primary, to, onClick, selected } = props;

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <RouterLink to={to ? to : null} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem
      button
      component={renderLink}
      onClick={onClick ? onClick : null}
      selected={selected}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}

function Topbar(props) {
  const {
    currentLocale,
    currentUser,
    currentPathKey,
    updateLocale,

    logout
  } = props;

  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [localeAnchorEl, setLocaleAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [smallMenuAnchorEl, setSmallMenuAnchorEl] = useState(null);

  const shouldDisplayMenu = () => {
    if (currentPathKey === 'setup') {
      return false;
    }

    return true;
  };

  const openLocaleMenu = (event) => {
    setLocaleAnchorEl(event.currentTarget);
  };

  const closeLocaleMenu = () => {
    setLocaleAnchorEl(null);
  };

  const openUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserAnchorEl(null);
  };

  const openSmallMenu = (event) => {
    setSmallMenuAnchorEl(event.currentTarget);
  };

  const closeSmallMenu = () => {
    setSmallMenuAnchorEl(null);
  };

  useEffect(() => {
    return history.listen((location) => {
      if (smallMenuAnchorEl) {
        closeSmallMenu();
      }
    });
  }, [history, smallMenuAnchorEl]);

  return (
    <AppBar position="fixed" elevation={1}>
      <Toolbar className="topbar">
        {isSmallScreen ? (
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box textAlign="left" pl={1}>
                <img
                  src={MasLogoImage}
                  alt="logo"
                  className={classes.logoImageSmall}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box textAlign="right">
                <IconButton color="primary" onClick={openSmallMenu}>
                  <Icon path={mdiDotsVertical} size={1} color="#ffffff" />
                </IconButton>
                <Drawer
                  variant="temporary"
                  anchor="bottom"
                  open={Boolean(smallMenuAnchorEl)}
                  onClose={closeSmallMenu}
                >
                  <SmallScreenTopbarWrapper>
                    <Box textAlign="right">
                      <IconButton onClick={closeSmallMenu}>
                        <Icon path={mdiClose} size={1} color="#ffffff" />
                      </IconButton>
                    </Box>
                    {shouldDisplayMenu() && (
                      <List
                        component="nav"
                        dense={true}
                        subheader={
                          <ListSubheader component="div">
                            <Box
                              color="text.primary"
                              className="text-secondary-bold-drop"
                            >
                              {intl.formatMessage({
                                id: 'label.menu'
                              })}
                            </Box>
                          </ListSubheader>
                        }
                      >
                        <ListItemLink
                          button
                          key="dashboard"
                          to={global.pathFor('DASHBOARD_PATH')}
                          primary={intl.formatMessage({
                            id: 'button.dashboard'
                          })}
                          selected={currentPathKey === 'dashboard'}
                          icon={
                            currentPathKey === 'dashboard' ? (
                              <Icon
                                path={mdiCheckboxBlankCircle}
                                size={0.4}
                                color="#ffffff"
                              />
                            ) : null
                          }
                        />
                        <ListItemLink
                          button
                          key="users"
                          to={global.pathFor('USERS_PATH')}
                          primary={intl.formatMessage({ id: 'button.users' })}
                          selected={currentPathKey === 'users'}
                          icon={
                            currentPathKey === 'users' ? (
                              <Icon
                                path={mdiCheckboxBlankCircle}
                                size={0.4}
                                color="#ffffff"
                              />
                            ) : null
                          }
                        />
                        <ListItemLink
                          button
                          key="locations"
                          to={global.pathFor('LOCATIONS_PATH')}
                          primary={intl.formatMessage({
                            id: 'button.locations'
                          })}
                          selected={currentPathKey === 'locations'}
                          icon={
                            currentPathKey === 'locations' ? (
                              <Icon
                                path={mdiCheckboxBlankCircle}
                                size={0.4}
                                color="#ffffff"
                              />
                            ) : null
                          }
                        />
                        <ListItemLink
                          button
                          key="report"
                          to={global.pathFor('REPORT_PATH')}
                          primary={intl.formatMessage({
                            id: 'button.report'
                          })}
                          selected={currentPathKey === 'report'}
                          icon={
                            currentPathKey === 'report' ? (
                              <Icon
                                path={mdiCheckboxBlankCircle}
                                size={0.4}
                                color="#ffffff"
                              />
                            ) : null
                          }
                        />
                      </List>
                    )}
                    {shouldDisplayMenu() && <Divider />}
                    {shouldDisplayMenu() && (
                      <List
                        component="nav"
                        dense={true}
                        subheader={
                          <ListSubheader component="div">
                            <Box
                              color="text.primary"
                              className="text-secondary-bold-drop"
                            >
                              {intl.formatMessage({
                                id: 'label.my_account'
                              })}
                            </Box>
                          </ListSubheader>
                        }
                      >
                        <ListItemLink
                          button
                          key="my_profile"
                          to={global.pathFor('PROFILE_PATH')}
                          primary={intl.formatMessage({
                            id: 'button.my_profile'
                          })}
                          selected={currentPathKey === 'profile'}
                          icon={
                            currentPathKey === 'profile' ? (
                              <Icon
                                path={mdiCheckboxBlankCircle}
                                size={0.4}
                                color="#ffffff"
                              />
                            ) : null
                          }
                        />
                        <ListItemLink
                          button
                          key="company"
                          to={global.pathFor('COMPANY_PATH')}
                          primary={intl.formatMessage({
                            id: 'button.company'
                          })}
                          selected={currentPathKey === 'company'}
                          icon={
                            currentPathKey === 'company' ? (
                              <Icon
                                path={mdiCheckboxBlankCircle}
                                size={0.4}
                                color="#ffffff"
                              />
                            ) : null
                          }
                        />
                      </List>
                    )}
                    {shouldDisplayMenu() && <Divider />}
                    <List
                      component="nav"
                      dense={true}
                      subheader={
                        <ListSubheader component="div">
                          <Box
                            color="text.primary"
                            className="text-secondary-bold-drop"
                          >
                            {intl.formatMessage({
                              id: 'label.change_language'
                            })}
                          </Box>
                        </ListSubheader>
                      }
                    >
                      <ListItem button>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'language.en'
                          })}
                          onClick={() => {
                            closeSmallMenu();
                            updateLocale('en');
                          }}
                        />
                      </ListItem>
                      <ListItem button>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'language.th'
                          })}
                          onClick={() => {
                            closeSmallMenu();
                            updateLocale('th');
                          }}
                        />
                      </ListItem>
                    </List>
                    <Divider />
                    <List component="nav" dense={true}>
                      <ListItem
                        button
                        onClick={() => {
                          closeUserMenu();
                          logout();
                        }}
                      >
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'button.sign_out'
                          })}
                        />
                      </ListItem>
                    </List>
                  </SmallScreenTopbarWrapper>
                </Drawer>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={6} sm={6} md={7} lg={7}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3} sm={3} md={2} lg={3}>
                  <Box px={1} textAlign="center">
                    <img
                      src={MasLogoImage}
                      alt="logo"
                      className={classes.logoImage}
                    />
                  </Box>
                </Grid>
                {shouldDisplayMenu() && (
                  <Grid item xs={3} sm={3} md={2} lg={2}>
                    <MenuButton
                      pathKey="dashboard"
                      to={`${global.pathFor('DASHBOARD_PATH')}`}
                    >
                      {intl.formatMessage({ id: 'button.dashboard' })}
                    </MenuButton>
                  </Grid>
                )}
                {shouldDisplayMenu() && (
                  <Grid item xs={3} sm={3} md={4} lg={3}>
                    <MenuButton
                      pathKey="users"
                      to={`${global.pathFor('USERS_PATH')}`}
                    >
                      {intl.formatMessage({ id: 'button.users' })}
                    </MenuButton>
                  </Grid>
                )}
                {shouldDisplayMenu() && (
                  <Grid item xs={3} sm={3} md={2} lg={2}>
                    <MenuButton
                      pathKey="locations"
                      to={`${global.pathFor('LOCATIONS_PATH')}`}
                    >
                      {intl.formatMessage({ id: 'button.locations' })}
                    </MenuButton>
                  </Grid>
                )}
                {shouldDisplayMenu() && (
                  <Grid item xs={3} sm={3} md={2} lg={2}>
                    <MenuButton
                      pathKey="report"
                      to={`${global.pathFor('REPORT_PATH')}`}
                    >
                      {intl.formatMessage({ id: 'button.report' })}
                    </MenuButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={1}></Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} className="border-right">
                  <Button
                    fullWidth
                    variant="text"
                    onClick={openLocaleMenu}
                    className="locale-button"
                  >
                    {intl.formatMessage({
                      id: 'label.change_language'
                    })}
                  </Button>
                  <Menu
                    anchorEl={localeAnchorEl}
                    keepMounted
                    open={Boolean(localeAnchorEl)}
                    onClose={closeLocaleMenu}
                    className="topbar-locale-menu"
                  >
                    <MenuItem
                      onClick={() => {
                        closeLocaleMenu();
                        updateLocale('en');
                      }}
                      selected={currentLocale === 'en'}
                      style={{ minWidth: '180px' }}
                    >
                      {intl.formatMessage({
                        id: 'language.en'
                      })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeLocaleMenu();
                        updateLocale('th');
                      }}
                      selected={currentLocale === 'th'}
                      style={{ minWidth: '180px' }}
                    >
                      {intl.formatMessage({
                        id: 'language.th'
                      })}
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  {shouldDisplayMenu() ? (
                    <Button
                      fullWidth
                      variant="text"
                      startIcon={
                        <Icon
                          path={mdiAccountCircle}
                          size={1}
                          color="#ffffff"
                        />
                      }
                      onClick={openUserMenu}
                      className="user-button"
                    >
                      {currentUser
                        ? currentLocale === 'en'
                          ? `${currentUser.first_name_en || ''} ${
                              currentUser.last_name_en[0] || ''
                            }.`
                          : `${currentUser.first_name || ''}`
                        : ''}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="text"
                      startIcon={
                        <Icon
                          path={mdiAccountCircle}
                          size={1}
                          color="#ffffff"
                        />
                      }
                      onClick={() => {
                        closeUserMenu();
                        logout();
                      }}
                      className="user-button"
                    >
                      {intl.formatMessage({
                        id: 'button.sign_out'
                      })}
                    </Button>
                  )}
                  <Menu
                    anchorEl={userAnchorEl}
                    keepMounted
                    open={Boolean(userAnchorEl)}
                    onClose={closeUserMenu}
                    className="topbar-user-menu"
                  >
                    <MenuItem
                      component={RouterLink}
                      to={`${global.pathFor('PROFILE_PATH')}`}
                      style={{ minWidth: '180px' }}
                    >
                      {intl.formatMessage({
                        id: 'button.my_profile'
                      })}
                    </MenuItem>
                    <MenuItem
                      component={RouterLink}
                      to={`${global.pathFor('COMPANY_PATH')}`}
                      style={{ minWidth: '180px' }}
                    >
                      {intl.formatMessage({
                        id: 'button.company'
                      })}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        closeUserMenu();
                        logout();
                      }}
                      style={{ minWidth: '180px' }}
                    >
                      {intl.formatMessage({
                        id: 'button.sign_out'
                      })}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default connect(
  (state) => ({
    currentLocale: state.app.locale,
    currentPathKey: state.app.pathKey,
    currentUser: state.auth.user
  }),
  {
    updateLocale,
    logout
  }
)(Topbar);
