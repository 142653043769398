export const ErrorCodeMessages = {
  code_000000: {
    en: 'Network error. Please try again.',
    th: 'ระบบเกิดความขัดข้อง กรุณาลองอีกครั้ง'
  },
  code_140101: {
    en: 'Authentication Token is expired or invalid. Please sign-in again.',
    th:
      'โทเคนสำหรับการเข้าถึงข้อมูลหมดอายุ หรือไม่ถูกต้อง กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
  },
  code_400201: {
    en: 'License and/or Email is incorrect.',
    th: 'ไลเซนส์ และ/หรือ อีเมลไม่ถูกต้อง'
  },
  code_400203: {
    en: 'Email and/or password is incorrect.',
    th: 'อีเมล และ/หรือ รหัสผ่านไม่ถูกต้อง'
  },
  code_400204: {
    en:
      'Cannot reset password. Reset password code might be expired or invalid.',
    th:
      'ไม่สามารถตั้งค่ารหัสผ่านใหม่ได้ โค้ดสำหรับตั้งค่ารหัสผ่านใหม่อาจจะหมดอายุหรือไม่ถูกต้อง'
  },
  code_400205: {
    en: 'Cannot create new employee. User credit limit exceeded.',
    th: 'ไม่สามารถสร้างพนักงานใหม่ได้ จำนวนพนักงานเต็มเครดิตแล้ว'
  },
  code_400206: {
    en: 'Mobile No. cannot be duplicated.',
    th: 'หมายเลขโทรศัพท์มือถือไม่สามารถซ้ำกันได้'
  },
  code_400207: {
    en: 'Email cannot be duplicated.',
    th: 'อีเมลไม่สามารถซ้ำกันได้'
  },
  code_400208: {
    en: 'Employee not found.',
    th: 'ไม่พบพนักงานที่ต้องการค้นหา'
  }
};
